import React from "react";

const Nvps = () => {
  return (
    <>
      <div className="header-page">
        <div className="overlay hero" />
        <video
          playsInline="playsinline"
          autoPlay="autoplay"
          muted="muted"
          loop="loop"
        >
          <source src="/videos/index/n-vps.mp4" type="video/mp4" />
        </video>
        <div className="container h-100">
          <div className="d-flex h-100 text-center align-items-center">
            <div className="w-100 text-white">
              <h1 className="display-3">N-VPS Hosting</h1>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <h2 className="text-center heading">Plans</h2>
      <br />
      <div className="container">
        <div className="row">
          <div
            className="card bg-light rounded-lg shadow mx-auto mb-4"
            style={{ width: "22rem" }}
          >
            <div className="card-body text-center">
              <h5 className="card-title plan-name">N-VPS-01</h5>
              <h6 className="card-subtitle mb-2 text-muted starting-from">
                Only
              </h6>
              <h2 className="price">$6.00/mo</h2>
              <p className="card-text view-text">4GB RAM</p>
              <p className="card-text view-text">28GB Storage</p>
              <p className="card-text view-text">1 n-vCore</p>
              <p className="card-text view-text">Intel Core i9-9900K</p>
              <a href="https://billing.hyehosting.com/cart.php?a=add&pid=1">
                <button className="btn btn-mc card-btn">Order Now</button>
              </a>
            </div>
          </div>
          <div
            className="card bg-light rounded-lg shadow mx-auto mb-4"
            style={{ width: "22rem" }}
          >
            <div className="card-body text-center">
              <h5 className="card-title plan-name">N-VPS-02</h5>
              <h6 className="card-subtitle mb-2 text-muted starting-from">
                Only
              </h6>
              <h2 className="price">$9.00/mo</h2>
              <p className="card-text view-text">6GB RAM</p>
              <p className="card-text view-text">42GB Storage</p>
              <p className="card-text view-text">2 n-vCores</p>
              <p className="card-text view-text">Intel Core i9-9900K</p>
              <a href="https://billing.hyehosting.com/cart.php?a=add&pid=2">
                <button className="btn btn-mc card-btn">Order Now</button>
              </a>
            </div>
          </div>
          <div
            className="card bg-light rounded-lg shadow mx-auto mb-4"
            style={{ width: "22rem" }}
          >
            <div className="card-body text-center">
              <h5 className="card-title plan-name">N-VPS-03</h5>
              <h6 className="card-subtitle mb-2 text-muted starting-from">
                Only
              </h6>
              <h2 className="price">$12.00/mo</h2>
              <p className="card-text view-text">8GB RAM</p>
              <p className="card-text view-text">56GB Storage</p>
              <p className="card-text view-text">3 n-vCores</p>
              <p className="card-text view-text">Intel Core i9-9900K</p>
              <a href="https://billing.hyehosting.com/cart.php?a=add&pid=3">
                <button className="btn btn-mc card-btn">Order Now</button>
              </a>
            </div>
          </div>
          <div
            className="card bg-light rounded-lg shadow mx-auto mb-4"
            style={{ width: "22rem" }}
          >
            <div className="card-body text-center">
              <h5 className="card-title plan-name">N-VPS-04</h5>
              <h6 className="card-subtitle mb-2 text-muted starting-from">
                Only
              </h6>
              <h2 className="price">$18.00/mo</h2>
              <p className="card-text view-text">12GB RAM</p>
              <p className="card-text view-text">84GB Storage</p>
              <p className="card-text view-text">3 n-vCores</p>
              <p className="card-text view-text">Intel Core i9-9900K</p>
              <a href="https://billing.hyehosting.com/cart.php?a=add&pid=4">
                <button className="btn btn-mc card-btn">Order Now</button>
              </a>
            </div>
          </div>
          <div
            className="card bg-light rounded-lg shadow mx-auto mb-4"
            style={{ width: "22rem" }}
          >
            <div className="card-body text-center">
              <h5 className="card-title plan-name">N-VPS-05</h5>
              <h6 className="card-subtitle mb-2 text-muted starting-from">
                Only
              </h6>
              <h2 className="price">$24.00/mo</h2>
              <p className="card-text view-text">16GB RAM</p>
              <p className="card-text view-text">112GB Storage</p>
              <p className="card-text view-text">4 n-vCores</p>
              <p className="card-text view-text">Intel Core i9-9900K</p>
              <a href="https://billing.hyehosting.com/cart.php?a=add&pid=5">
                <button className="btn btn-mc card-btn">Order Now</button>
              </a>
            </div>
          </div>
          <div
            className="card bg-light rounded-lg shadow mx-auto mb-4"
            style={{ width: "22rem" }}
          >
            <div className="card-body text-center">
              <h5 className="card-title plan-name">N-VPS-06</h5>
              <h6 className="card-subtitle mb-2 text-muted starting-from">
                Only
              </h6>
              <h2 className="price">$30.00/mo</h2>
              <p className="card-text view-text">24GB RAM</p>
              <p className="card-text view-text">168GB Storage</p>
              <p className="card-text view-text">5 n-vCores</p>
              <p className="card-text view-text">Intel Core i9-9900K</p>
              <a href="https://billing.hyehosting.com/cart.php?a=add&pid=6">
                <button className="btn btn-mc card-btn">Order Now</button>
              </a>
            </div>
          </div>
          <div
            className="card bg-light rounded-lg shadow mx-auto mb-4"
            style={{ width: "22rem" }}
          >
            <div className="card-body text-center">
              <h5 className="card-title plan-name">N-VPS-07</h5>
              <h6 className="card-subtitle mb-2 text-muted starting-from">
                Only
              </h6>
              <h2 className="price">$32.00/mo</h2>
              <p className="card-text view-text">32GB RAM</p>
              <p className="card-text view-text">224GB Storage</p>
              <p className="card-text view-text">6 n-vCores</p>
              <p className="card-text view-text">Intel Core i9-9900K</p>
              <a href="https://billing.hyehosting.com/cart.php?a=add&pid=7">
                <button className="btn btn-mc card-btn">Order Now</button>
              </a>
            </div>
          </div>
          <div
            className="card bg-light rounded-lg shadow mx-auto mb-4"
            style={{ width: "22rem" }}
          >
            <div className="card-body text-center">
              <h5 className="card-title plan-name">N-VPS-08</h5>
              <h6 className="card-subtitle mb-2 text-muted starting-from">
                Only
              </h6>
              <h2 className="price">$40.00/mo</h2>
              <p className="card-text view-text">48GB RAM</p>
              <p className="card-text view-text">336GB Storage</p>
              <p className="card-text view-text">7 n-vCores</p>
              <p className="card-text view-text">Intel Core i9-9900K</p>
              <a href="https://billing.hyehosting.com/cart.php?a=add&pid=8">
                <button className="btn btn-mc card-btn">Order Now</button>
              </a>
            </div>
          </div>
          <div
            className="card bg-light rounded-lg shadow mx-auto mb-4"
            style={{ width: "22rem" }}
          >
            <div className="card-body text-center">
              <h5 className="card-title plan-name">N-VPS-09</h5>
              <h6 className="card-subtitle mb-2 text-muted starting-from">
                Only
              </h6>
              <h2 className="price">$48.00/mo</h2>
              <p className="card-text view-text">64GB RAM</p>
              <p className="card-text view-text">448GB Storage</p>
              <p className="card-text view-text">8 n-vCores</p>
              <p className="card-text view-text">Intel Core i9-9900K</p>
              <a href="https://billing.hyehosting.com/cart.php?a=add&pid=9">
                <button className="btn btn-mc card-btn">Order Now</button>
              </a>
            </div>
          </div>
          <div
            className="card bg-light rounded-lg shadow mx-auto mb-4"
            style={{ width: "22rem" }}
          >
            <div className="card-body text-center">
              <h5 className="card-title plan-name">N-VPS-10</h5>
              <h6 className="card-subtitle mb-2 text-muted starting-from">
                Only
              </h6>
              <h2 className="price">$63.00/mo</h2>
              <p className="card-text view-text">84GB RAM</p>
              <p className="card-text view-text">588GB Storage</p>
              <p className="card-text view-text">10 n-vCores</p>
              <p className="card-text view-text">Intel Core i9-9900K</p>
              <a href="https://billing.hyehosting.com/cart.php?a=add&pid=10">
                <button className="btn btn-mc card-btn">Order Now</button>
              </a>
            </div>
          </div>
          <div
            className="card bg-light rounded-lg shadow mx-auto mb-4"
            style={{ width: "22rem" }}
          >
            <div className="card-body text-center">
              <h5 className="card-title plan-name">N-VPS-11</h5>
              <h6 className="card-subtitle mb-2 text-muted starting-from">
                Only
              </h6>
              <h2 className="price">$84.00/mo</h2>
              <p className="card-text view-text">112GB RAM</p>
              <p className="card-text view-text">784GB Storage</p>
              <p className="card-text view-text">10 n-vCores</p>
              <p className="card-text view-text">Intel Core i9-9900K</p>
              <a href="https://billing.hyehosting.com/cart.php?a=add&pid=11">
                <button className="btn btn-mc card-btn">Order Now</button>
              </a>
            </div>
          </div>
          <div
            className="card bg-light rounded-lg shadow mx-auto mb-4"
            style={{ width: "22rem" }}
          >
            <div className="card-body text-center">
              <h5 className="card-title plan-name">N-VPS-12</h5>
              <h6 className="card-subtitle mb-2 text-muted starting-from">
                Only
              </h6>
              <h2 className="price">$96.00/mo</h2>
              <p className="card-text view-text">128GB RAM</p>
              <p className="card-text view-text">896GB Storage</p>
              <p className="card-text view-text">16 n-vCores</p>
              <p className="card-text view-text">Intel Core i9-9900K</p>
              <a href="https://billing.hyehosting.com/cart.php?a=add&pid=12">
                <button className="btn btn-mc card-btn">Order Now</button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </>
  );
};

export default Nvps;
