import Switch from "./components/CustomSwitch/";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from "./pages/Home";
import Navbar from "./components/Navbar/";
import Footer from "./components/Footer/";
import Minecraft from "./pages/Minecraft";
import Nvps from "./pages/Nvps";
function App() {
  return (
    <>
      <Router>
        <Navbar></Navbar>
        <Switch>
          <Route path="/" component={Home} exact></Route>
          <Route path="/minecraft" component={Minecraft}></Route>
          <Route path="/n-vps" component={Nvps}></Route>
        </Switch>
        <Footer></Footer>
      </Router>
    </>
  );
}

export default App;
