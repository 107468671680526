import React from "react";
import { Link } from "react-router-dom";
const Home = () => {
  return (
    <>
      <header>
        <div className="overlay hero" />
        <video
          defer
          playsInline="playsinline"
          autoPlay="autoplay"
          muted="muted"
          loop="loop"
          poster="/images/background.png"
        >
          <source src="/videos/index/background.mp4" type="video/mp4" />
        </video>
        <div className="container h-100">
          <div className="d-flex h-100 text-center align-items-center">
            <div className="w-100 text-white">
              <h1 className="display-3">High Quality Hosting</h1>
              <h1 className="mb-0">At Affordable Pricing</h1>
            </div>
          </div>
        </div>
      </header>
      <br />
      <section className="features">
        <br />
        <h2 className="text-center heading" style={{ fontWeight: "bold" }}>
          Why choose Hye Hosting?
        </h2>
        <br />
        <div className="container">
          <div className="row">
            <div className="col-md-4 mb-4 bg-light rounded-lg mx-auto shadow">
              <br />
              <i
                className="fa fa-signal circle-icon-blue"
                style={{ fontSize: "24px", color: "white" }}
              />
              <br />
              <br />
              <p className="font-weight-bold features-header">Reliable</p>
              <p className="features-info">
                You don't need to worry about downtime with us! We have a 99%
                uptime guarantee.
              </p>
              <br />
            </div>
            <div className="col-md-3 mb-4 bg-light rounded-lg mx-auto shadow">
              <br />
              <i
                className="fa fa-check circle-icon-blue"
                style={{ fontSize: "24px", color: "white" }}
              />
              <br />
              <br />
              <p className="font-weight-bold features-header">Simple</p>
              <p className="features-info">
                Our services are designed with simplicity in mind while
                delivering powerful features.
              </p>
              <br />
            </div>
            <div className="col-md-4 mb-4 bg-light rounded-lg shadow mx-auto">
              <br />
              <i
                className="fa fa-money circle-icon-blue"
                style={{ fontSize: "24px", color: "white" }}
              />
              <br />
              <br />
              <p className="font-weight-bold features-header">Affordable</p>
              <p className="features-info">
                Hye Hosting's affordable pricing makes server hosting more
                accessible to everyone.
              </p>
              <br />
            </div>
          </div>
        </div>
      </section>
      <br />
      <br />
      <br />
      <br />
      <section
        className="support"
        style={{ backgroundColor: "rgb(35, 35, 35)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h1 className="mt-5">Ararat</h1>
              <h6 style={{ color: "#d3d3d3" }}>
                The Best Server Control Panel In The Industry
              </h6>
              <br />
              <p>
                Ararat provides you with the speed and simplicity you need to
                manage your server. Wether it's a Minecraft Server, or an N-VPS,
                Ararat has you covered.
              </p>
              <br />
              <button className="btn btn-vps card-btn mb-5">Learn More</button>
            </div>
            <div className="mt-8">
              <img
                className="p-3 mt-5 mb-4 d-none d-lg-block"
                src="/images/index/unknown.png"
                style={{ width: "558.72px", height: "329.76px" }}
              />
            </div>
            <br />
          </div>
        </div>
      </section>
      <br />
      <br />
      <br />
      <br />
      <h2 className="text-center heading">Products</h2>
      <br />
      <div className="container">
        <div className="row">
          <div
            className="card bg-light rounded-lg shadow mx-auto mb-4"
            style={{ width: "22rem" }}
          >
            <div className="card-body text-center">
              <h5 className="card-title plan-name">Minecraft Hosting</h5>
              <h6 className="card-subtitle mb-2 text-muted starting-from">
                Starting From
              </h6>
              <h2 className="price">$0.75/mo</h2>
              <p className="card-text view-text">Ararat Panel</p>
              <p className="card-text view-text">Full File Access</p>
              <p className="card-text view-text">DDoS Protection</p>
              <Link to="/minecraft">
                {" "}
                <button className="btn btn-disc card-btn">View Plans</button>
              </Link>
            </div>
          </div>
          <div
            className="card bg-light rounded-lg shadow mx-auto mb-4"
            style={{ width: "22rem" }}
          >
            <div className="card-body text-center">
              <h5 className="card-title plan-name">Web Hosting</h5>
              <h6 className="card-subtitle mb-2 text-muted starting-from">
                Starting From
              </h6>
              <h2 className="price">$2.50/mo</h2>
              <p className="card-text view-text">DirectAdmin Control Panel</p>
              <p className="card-text view-text">Unmetered Bandwidth</p>
              <p className="card-text view-text">DDoS Protection</p>
              <button className="btn btn-disc card-btn">Coming Soon</button>
            </div>
          </div>
          <div
            className="card bg-light rounded-lg shadow mx-auto mb-4"
            style={{ width: "22rem" }}
          >
            <div className="card-body text-center">
              <h5 className="card-title plan-name">N-VPS Hosting</h5>
              <h6 className="card-subtitle mb-2 text-muted starting-from">
                Starting From
              </h6>
              <h2 className="price">$3.00/mo</h2>
              <p className="card-text view-text">Ararat Panel</p>
              <p className="card-text view-text">Unmetered Bandwidth</p>
              <p className="card-text view-text">DDoS Protection</p>
              <Link to="/n-vps">
                <button className="btn btn-vps card-btn">View Plans</button>{" "}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
    </>
  );
};

export default Home;
