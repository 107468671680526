import React from "react";
import { Link } from "react-router-dom";
const Navbar = () => {
  var styles = {
    height: "80px",
    transition: "background-color 0.6s ease 0s",
    position: "fixed",
    width: "100%",
  };
  return (
    <>
      <div className="container-fluid">
        <nav
          id="nav"
          className={`navbar navbar-expand-lg navbar-dark custom-nav-full fixed-top`}
          style={styles}
        >
          <div className="container">
            <Link to="/" className="navbar-brand">
              <img
                src="/images/logo.png"
                width={50}
                alt=""
                className="d-inline-block align-middle mr-2"
              />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mr-auto">
                <li className="nav-item">
                  <Link className="nav-link" id="homenav" to="/">
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" id="minecraft" to="minecraft">
                    Minecraft
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" id="n-vps" to="n-vps">
                    N-VPS
                  </Link>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://ararat.hyehosting.com"
                    id="dashnav"
                  >
                    Ararat
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Navbar;
