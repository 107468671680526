import React from "react";

const Minecraft = () => {
  return (
    <>
      <div className="header-page">
        <div className="overlay hero" />
        <video
          defer
          playsInline="playsinline"
          autoPlay="autoplay"
          muted="muted"
          loop="loop"
        >
          <source src="/videos/index/minecraft.mp4" type="video/mp4" />
        </video>
        <div className="container h-100">
          <div className="d-flex h-100 text-center align-items-center">
            <div className="w-100 text-white">
              <h1 className="display-3">Minecraft Server Hosting</h1>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <h2 className="text-center heading">Plans</h2>
      <br />
      <div className="container">
        <div className="row">
          <div
            className="card bg-light rounded-lg shadow mx-auto mb-4"
            style={{ width: "22rem" }}
          >
            <div className="card-body text-center">
              <h5 className="card-title plan-name">Coal</h5>
              <h6 className="card-subtitle mb-2 text-muted starting-from">
                Only
              </h6>
              <h2 className="price">$0.75/mo</h2>
              <p className="card-text view-text">1GB RAM</p>
              <p className="card-text view-text">7GB Storage</p>
              <p className="card-text view-text">1 Core</p>
              <p className="card-text view-text">Intel Core i9-9900K</p>
              <a href="https://billing.hyehosting.com/cart.php?a=add&pid=13">
                <button className="btn btn-mc card-btn">Order Now</button>
              </a>
            </div>
          </div>
          <div
            className="card bg-light rounded-lg shadow mx-auto mb-4"
            style={{ width: "22rem" }}
          >
            <div className="card-body text-center">
              <h5 className="card-title plan-name">Iron</h5>
              <h6 className="card-subtitle mb-2 text-muted starting-from">
                Only
              </h6>
              <h2 className="price">$1.50/mo</h2>
              <p className="card-text view-text">2GB RAM</p>
              <p className="card-text view-text">14GB Storage</p>
              <p className="card-text view-text">1 Core</p>
              <p className="card-text view-text">Intel Core i9-9900K</p>
              <a href="https://billing.hyehosting.com/cart.php?a=add&pid=14">
                <button className="btn btn-mc card-btn">Order Now</button>
              </a>
            </div>
          </div>
          <div
            className="card bg-light rounded-lg shadow mx-auto mb-4"
            style={{ width: "22rem" }}
          >
            <div className="card-body text-center">
              <h5 className="card-title plan-name">Gold</h5>
              <h6 className="card-subtitle mb-2 text-muted starting-from">
                Only
              </h6>
              <h2 className="price">$2.25/mo</h2>
              <p className="card-text view-text">3GB RAM</p>
              <p className="card-text view-text">21GB Storage</p>
              <p className="card-text view-text">3 Cores</p>
              <p className="card-text view-text">Intel Core i9-9900K</p>
              <a href="https://billing.hyehosting.com/cart.php?a=add&pid=15">
                <button className="btn btn-mc card-btn">Order Now</button>
              </a>
            </div>
          </div>
          <div
            className="card bg-light rounded-lg shadow mx-auto mb-4"
            style={{ width: "22rem" }}
          >
            <div className="card-body text-center">
              <h5 className="card-title plan-name">Diamond</h5>
              <h6 className="card-subtitle mb-2 text-muted starting-from">
                Only
              </h6>
              <h2 className="price">$3.00/mo</h2>
              <p className="card-text view-text">4GB RAM</p>
              <p className="card-text view-text">28GB Storage</p>
              <p className="card-text view-text">3 Cores</p>
              <p className="card-text view-text">Intel Core i9-9900K</p>
              <a href="https://billing.hyehosting.com/cart.php?a=add&pid=16">
                <button className="btn btn-mc card-btn">Order Now</button>
              </a>
            </div>
          </div>
          <div
            className="card bg-light rounded-lg shadow mx-auto mb-4"
            style={{ width: "22rem" }}
          >
            <div className="card-body text-center">
              <h5 className="card-title plan-name">Obsidian</h5>
              <h6 className="card-subtitle mb-2 text-muted starting-from">
                Only
              </h6>
              <h2 className="price">$3.75/mo</h2>
              <p className="card-text view-text">5GB RAM</p>
              <p className="card-text view-text">35GB Storage</p>
              <p className="card-text view-text">3 Cores</p>
              <p className="card-text view-text">Intel Core i9-9900K</p>
              <a href="https://billing.hyehosting.com/cart.php?a=add&pid=17">
                <button className="btn btn-mc card-btn">Order Now</button>
              </a>
            </div>
          </div>
          <div
            className="card bg-light rounded-lg shadow mx-auto mb-4"
            style={{ width: "22rem" }}
          >
            <div className="card-body text-center">
              <h5 className="card-title plan-name">Pro</h5>
              <h6 className="card-subtitle mb-2 text-muted starting-from">
                Only
              </h6>
              <h2 className="price">$4.50/mo</h2>
              <p className="card-text view-text">6GB RAM</p>
              <p className="card-text view-text">42GB Storage</p>
              <p className="card-text view-text">4 Cores</p>
              <p className="card-text view-text">Intel Core i9-9900K</p>
              <a href="https://billing.hyehosting.com/cart.php?a=add&pid=18">
                <button className="btn btn-mc card-btn">Order Now</button>
              </a>
            </div>
          </div>
          <div
            className="card bg-light rounded-lg shadow mx-auto mb-4"
            style={{ width: "22rem" }}
          >
            <div className="card-body text-center">
              <h5 className="card-title plan-name">Max</h5>
              <h6 className="card-subtitle mb-2 text-muted starting-from">
                Only
              </h6>
              <h2 className="price">$6.00/mo</h2>
              <p className="card-text view-text">8GB RAM</p>
              <p className="card-text view-text">56GB Storage</p>
              <p className="card-text view-text">4 Cores</p>
              <p className="card-text view-text">Intel Core i9-9900K</p>
              <a href="https://billing.hyehosting.com/cart.php?a=add&pid=19">
                <button className="btn btn-mc card-btn">Order Now</button>
              </a>
            </div>
          </div>
          <div
            className="card bg-light rounded-lg shadow mx-auto mb-4"
            style={{ width: "22rem" }}
          >
            <div className="card-body text-center">
              <h5 className="card-title plan-name">Ultra</h5>
              <h6 className="card-subtitle mb-2 text-muted starting-from">
                Only
              </h6>
              <h2 className="price">$7.50/mo</h2>
              <p className="card-text view-text">10GB RAM</p>
              <p className="card-text view-text">70GB Storage</p>
              <p className="card-text view-text">4 Cores</p>
              <p className="card-text view-text">Intel Core i9-9900K</p>
              <a href="https://billing.hyehosting.com/cart.php?a=add&pid=20">
                <button className="btn btn-mc card-btn">Order Now</button>
              </a>{" "}
            </div>
          </div>
          <div
            className="card bg-light rounded-lg shadow mx-auto mb-4"
            style={{ width: "22rem" }}
          >
            <div className="card-body text-center">
              <h5 className="card-title plan-name">Ultimate</h5>
              <h6 className="card-subtitle mb-2 text-muted starting-from">
                Only
              </h6>
              <h2 className="price">$12.00/mo</h2>
              <p className="card-text view-text">16GB RAM</p>
              <p className="card-text view-text">112GB Storage</p>
              <p className="card-text view-text">5 Cores</p>
              <p className="card-text view-text">Intel Core i9-9900K</p>
              <a href="https://billing.hyehosting.com/cart.php?a=add&pid=21">
                <button className="btn btn-mc card-btn">Order Now</button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </>
  );
};

export default Minecraft;
